import React from 'react'
import moment from 'moment'

import { chartColors } from '@components/chart'
import { Part } from '@components/table/type'
import { greenToRed } from '@helpers/green-to-red'
import { Tooltip } from '@material-tailwind/react'

export interface CovenantResponse {
  [key: string]: any
}

export enum CovenantType {
  ANALYTICS_PRO,
  MANAGE,
}

export abstract class CovenantDataBuilder {
  protected covenant: CovenantResponse
  protected data: CovenantResponse
  protected type: CovenantType

  protected specialCovenant = ''
  protected specialSeriesLabels: string[] = []
  protected specialSeriesFields: string[] = []
  protected selectedCohort = ''
  protected invertedColours = false
  protected cohortInterval: any
  protected cohortColumnName = ''

  protected setSelectedCohort: React.Dispatch<React.SetStateAction<string>>

  protected maxVal = 0
  protected minVal = 0

  /**
   *
   * @param type - define type of charts
   * @param setSelectedCohort - set function that controls chosen cohort and re-renders components onChange. Should be useState of the variable from the react component
   * @param selectedCohort - getter for the same property.
   * @param covenantData - raw data from lambda response
   * @param summary - additiona data. Is optional, when not provided, the raw data is used for properties mapping.
   */
  constructor(
    type: CovenantType,
    setSelectedCohort: React.Dispatch<React.SetStateAction<string>>,
    selectedCohort: string,
    covenantData: CovenantResponse,
    summary?: CovenantResponse
  ) {
    this.type = type
    this.setSelectedCohort = setSelectedCohort
    this.selectedCohort = selectedCohort

    switch (type) {
      case CovenantType.ANALYTICS_PRO: {
        this.covenant = covenantData
        // Analytics pro does not have summary. This assignment is to 'standardize' the builder.
        this.data = covenantData
        break
      }
      case CovenantType.MANAGE: {
        if (!summary) {
          throw new Error(
            `CovenantListResponse must be provided for manage type`
          )
        }
        this.covenant = covenantData
        this.data = summary
        break
      }
      default:
        throw new Error(`Unsupported covenant type ${type}`)
    }
  }

  /**
   * @param cohortInterval - define value for cohort interval
   */
  protected withCohortInterval(cohortInterval: any) {
    this.cohortInterval = cohortInterval
  }

  /**
   * @param specialCovenant - define value for special covenant
   */
  protected withSpecialCovenant(specialCovenant: string) {
    this.specialCovenant = specialCovenant
  }

  /**
   * @param invertedColours - define if cohort has to invert colours
   */
  protected withInvertedColours(invertedColours: boolean) {
    this.invertedColours = invertedColours
  }

  /**
   * @param columnName - define name of the cohort column
   */
  protected withCohortColumnName(columnName: string) {
    this.cohortColumnName = columnName
  }

  /**
   * @param specialSeriesLabel - define special series label name
   */
  protected withSpecialSeriesLabels(specialSeriesLabels: string[]) {
    this.specialSeriesLabels.push(...specialSeriesLabels)
  }

  /**
   * @param specialSeriesFields - define special series fields name
   */
  protected withSpecialSeriesFields(specialSeriesFields: string[]) {
    this.specialSeriesFields.push(...specialSeriesFields)
  }

  protected countTriggerKeys = (obj: any) => {
    let count = 0
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        key.startsWith('trigger_') &&
        !isNaN(parseInt(key.split('_')[1]))
      ) {
        count++
      }
    }
    return count
  }

  // ***IMPORTANT*** setColor is a callback!
  protected setColor(field: any, data: any) {
    if (
      this.type === CovenantType.MANAGE &&
      data.x > moment.utc(this.data.date_as_of).valueOf()
    ) {
      return '#757d90'
    }

    if (!data?.triggers) {
      return greenToRed(0, true).toString()
    }
    const denominator = data.triggers
    const numerator = this.invertedColours
      ? denominator - (data.trigger_value ?? 0)
      : data.trigger_value ?? 0
    const percent = (numerator / denominator) * 100
    return greenToRed(percent, true).toString()
  }

  protected getTriggerColour(
    breachedTriggers: number,
    cohortTriggerCount: number
  ): string {
    if (breachedTriggers < 0) {
      return 'bg-jade'
    }
    const colors = ['bg-leather', 'bg-ember', 'bg-coral']
    const startIndex = colors.length - cohortTriggerCount
    return colors[startIndex + breachedTriggers]
  }

  protected getPreppedColumns(unprepColumns: any[]) {
    return unprepColumns
      .filter((c, i) => unprepColumns.indexOf(c) === i)
      .map(x => ({
        title: x,
        field: `value_${x}`,
        align: 'center',
        className: 'min-w-[100px]',
        render: (r: any, i: number, part: Part) => {
          const divElements = []
          //trigger value display
          if (
            (typeof r[`value_${x}`] !== 'undefined' || r.cohort == 'Summary') &&
            this.specialCovenant == 'Table'
          ) {
            for (let i = 1; i <= r.cohort_trigger_count; i++) {
              divElements.push(
                <div
                  key={i}
                  className={`border-b border-neutral-border-2 flex justify-center items-center -mx-3 py-2 ${
                    (part === 'head' && Number(r[`value_${x}`])) ||
                    (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                      ? r.cohort == 'Summary' && i == r.cohort_trigger_count
                        ? 'border-b-0'
                        : ''
                      : '!bg-neutral-border-2'
                  }`}
                >
                  {typeof r[`value_${x}`] !== 'undefined'
                    ? r.cohort != 'Summary'
                      ? Intl.NumberFormat(undefined, {
                          style: 'percent',
                          notation: 'compact',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(r[`trigger_${x}_${i}_val`] / 100)
                      : r[`trigger_${x}_${i}_val`]
                    : ''}
                </div>
              )
            }
          }
          //actual value
          if (typeof r[`value_${x}`] !== 'undefined' && r.cohort != 'Summary') {
            const textCol = 'primary-main'
            divElements.push(
              <div
                className={`flex justify-center items-center -mx-3 py-2 ${
                  (part === 'head' && Number(r[`value_${x}`])) ||
                  (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                    ? part === 'body'
                      ? `text-[${textCol}]`
                      : ''
                    : 'bg-neutral-border-2'
                }`}
              >
                {(part === 'head' && r[`value_${x}`]) ||
                (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                  ? r.cohort != 'Summary'
                    ? Intl.NumberFormat(undefined, {
                        style: 'percent',
                        notation: 'compact',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(r[`value_${x}`] / 100)
                    : r[`value_${x}`]
                  : ' '}
              </div>
            )
          }
          // Render the div elements inside a container div
          return divElements
        },
        props: (r: any, i: number, part: Part) => {
          return this.specialCovenant == 'Vintage' ||
            this.specialCovenant == 'Vintage Table Only'
            ? {
                className: `${
                  this.selectedCohort == r.cohort &&
                  typeof r[`value_${x}`] !== 'undefined'
                    ? `!bg-primary-surface-2`
                    : ``
                } ${
                  (part === 'head' && Number(r[`value_${x}`])) ||
                  (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                    ? part === 'body'
                      ? this.getTriggerColour(
                          Number(r[`trigger_${x}`]) - 1,
                          r.cohort_trigger_count
                        )
                      : ''
                    : '!bg-neutral-border-2'
                }`,
              }
            : {
                className: `  ${
                  this.selectedCohort == r.cohort &&
                  typeof r[`value_${x}`] !== 'undefined'
                    ? `!bg-cc-secondary-hover-deselected`
                    : ``
                } ${
                  (part === 'head' && Number(r[`value_${x}`])) ||
                  (part === 'body' && typeof r[`value_${x}`] !== 'undefined')
                    ? ''
                    : '!bg-neutral-border-2'
                }`,
              }
        },
      }))
      .sort((a, b) => (a.title > b.title ? 1 : -1))
  }

  protected getCohortColumn() {
    return this.specialCovenant == 'Table'
      ? {
          title: 'Cohort',
          field: 'cohort',
          align: 'center',
          className: 'min-w-[100px] sticky left-0',
          render: (r: any, i: number, part: Part) => {
            return part === 'body' ? (
              <div>
                <Tooltip
                  content={<span>{'Click here to chart this cohort'}</span>}
                  placement="right"
                >
                  {moment(r.cohort).isValid()
                    ? moment.utc(r.cohort).format('YYYY-MM-DD')
                    : r.cohort}
                </Tooltip>
              </div>
            ) : (
              r.cohort
            )
          },
          props: (r: any, i: number, part: Part) => {
            return {
              onClick: () => {
                this.selectedCohort = r.cohort
                this.setSelectedCohort(r.cohort)
              },
              className: `min-w-[100px] sticky left-0 ${
                part === 'body'
                  ? this.selectedCohort == r.cohort
                    ? `cursor-pointer !bg-yellow`
                    : `cursor-pointer hover:bg-secondary-surface`
                  : ''
              }`,
            }
          },
        }
      : {
          title: 'Cohort',
          field: 'cohort',
          align: 'center',
          className: 'min-w-[100px] sticky left-0',
          render: (r: any, i: number, part: Part) => {
            return part === 'body'
              ? moment.utc(r.cohort).format('MMM-YY')
              : r.cohort
          },
        }
  }

  protected getTriggerColumn() {
    return {
      title: 'Triggers',
      field: 'triggers',
      align: 'center',
      render: (r: any) => {
        const divElements = []

        for (let i = 1; i <= r.cohort_trigger_count; i++) {
          divElements.push(
            <div
              key={i}
              className={`flex justify-center items-center trigger-div -mx-3 py-2 ${
                r.cohort == 'Summary' && i == r.cohort_trigger_count
                  ? ''
                  : 'border-b '
              } border-neutral-border-2`}
            >
              Trigger {i}
            </div>
          )
        }
        r.cohort != 'Summary' &&
          divElements.push(
            <div className="flex justify-center items-center -mx-3 py-2">
              Value
            </div>
          )
        return divElements
      },
      props: (r: any) => {
        return {
          className: `min-w-[100px] sticky left-0  ${
            this.selectedCohort == r.cohort
              ? `!bg-cc-secondary-hover-deselected`
              : `bg-white`
          }`,
        }
      },
    }
  }

  protected getUnpreppedTableData(
    specialSeriesFields: string[],
    summaryVals: any
  ) {
    return [this.covenant]
      .reduce((p: any[], c) => {
        return [...p, ...(c.data ?? [])]
      }, [])
      .reduce((p, c) => {
        const key = `value_${c[this.cohortInterval]}`
        const trigger_key = `trigger_${c[this.cohortInterval]}`
        const existingIndex = p.findIndex((x: any) => x.cohort === c.cohort)

        if (existingIndex >= 0) {
          const newObj = {
            ...p[existingIndex],
            [key]: c[this.cohortColumnName],
            [trigger_key]: c.trigger,
            trigger_value: c.trigger_value,
          }
          for (let i = 1; i <= this.countTriggerKeys(c); i++) {
            c[`trigger_${i}`] > this.maxVal && (this.maxVal = c[`trigger_${i}`])
            c[`trigger_${i}`] < this.minVal && (this.minVal = c[`trigger_${i}`])

            newObj[`${trigger_key}_${i}_val`] = c[`trigger_${i}`]
            newObj[`cohort_trigger_count`] = this.countTriggerKeys(c)

            // Update summary for each trigger
            summaryVals[`cohort`] = 'Summary'
            summaryVals[`cohort_trigger_count`] = this.countTriggerKeys(c)

            summaryVals[key] =
              c.trigger == i
                ? (summaryVals[key] || 0) + 1
                : summaryVals[key] || 0

            summaryVals[`${trigger_key}_${i}_val`] =
              c.trigger == i
                ? (summaryVals[key] || 0) + 1
                : summaryVals[key] || 0
          }
          //ensures special series plotted are visible by enforcing min & max chart values
          for (let j = 1; j <= specialSeriesFields.length; j++) {
            c[specialSeriesFields[j]] > this.maxVal &&
              (this.maxVal = c[specialSeriesFields[j]])
            c[specialSeriesFields[j]] < this.minVal &&
              (this.minVal = c[specialSeriesFields[j]])
          }

          p[existingIndex] = newObj

          return p
        } else {
          const newObj = {
            cohort: c.cohort,
            [key]: c[this.cohortColumnName],
            [trigger_key]: c.trigger,
            trigger_value: c.trigger_value,
          }
          for (let i = 1; i <= this.countTriggerKeys(c); i++) {
            newObj[`${trigger_key}_${i}_val`] = c[`trigger_${i}`]
            newObj[`cohort_trigger_count`] = this.countTriggerKeys(c)

            // Update summary for each trigger
            summaryVals[`cohort`] = 'Summary'
            summaryVals[`cohort_trigger_count`] = this.countTriggerKeys(c)
            summaryVals[key] =
              c.trigger == i
                ? (summaryVals[key] || 0) + 1
                : summaryVals[key] || 0

            summaryVals[`${trigger_key}_${i}_val`] =
              c.trigger == i
                ? (summaryVals[key] || 0) + 1
                : summaryVals[key] || 0
          }
          return [...p, newObj]
        }
      }, [])
      .sort((a: any, b: any) => (a.cohort > b.cohort ? 1 : -1))
  }

  protected getUnpreppedTriggers(unprepedData: any[]) {
    return unprepedData
      .sort((a, b) =>
        a[`${this.cohortInterval}`] > b[`${this.cohortInterval}`] ? 1 : -1
      )

      .reduce((p, c) => {
        if (!Number(c.trigger_1)) {
          return p
        }
        const existingIndex = p.findIndex(
          (x: any) => x.trigger_1 === c.trigger_1
        )
        if (existingIndex >= 0) {
          if (
            !p[existingIndex][`${this.cohortInterval}s`].includes(
              c[`${this.cohortInterval}`]
            )
          ) {
            p[existingIndex][`${this.cohortInterval}s`].push(
              c[`${this.cohortInterval}`]
            )
          }
          return p
        } else {
          return [
            ...p,
            {
              [`${this.cohortInterval}s`]: [c[`${this.cohortInterval}`]],
              ...(this.data.triggers
                ? Array(this.data.triggers.length)
                    .fill('')
                    .reduce((_p, _, _i) => {
                      return {
                        ..._p,
                        [`trigger_${_i + 1}`]: c[`trigger_${_i + 1}`],
                      }
                    }, {})
                : {}),
            },
          ]
        }
      }, [])
      .sort((a: any, b: any) =>
        Number(Math.min(...a.mobs)) < Number(Math.min(...b.mobs)) ? -1 : 1
      )
  }

  public getLabelFormat = (covenant: any) => {
    if (covenant?.label_format) {
      return covenant?.label_format
    } else {
      return '#.00a%'
    }
  }

  protected getTriggerSeries(triggers: any) {
    return this.specialCovenant == 'Table'
      ? Array(this.data.triggers?.length)
          .fill('')
          .map((_, _i) => {
            return {
              tooltipValueFormat:
                this.selectedCohort == 'Summary'
                  ? ''
                  : this.getLabelFormat(this.data),
              type:
                this.selectedCohort == 'Summary'
                  ? 'ColumnSeries'
                  : 'StepLineSeries',
              field: `trigger_${_i + 1}`,
              label: `Trigger ${_i + 1}`,
              hasBullet: false,
              noRisers: true,
            }
          })
      : triggers.reduce((p: any[], c: any) => {
          const mob_triggers = Array(
            this.data?.triggers ? this.data.triggers[0].trigger : {}
          )
            .fill('')
            .map((_, _i) => {
              return {
                tooltipValueFormat: this.getLabelFormat(this.data),
                type: 'StepLineSeries',
                field: `trigger_${_i + 1}_${c[this.cohortInterval]}`,
                label: `MoB ${c[`${this.cohortInterval}s`]?.[0]}${
                  c[`${this.cohortInterval}s`].length > 1
                    ? `-${
                        c[`${this.cohortInterval}s`]?.[
                          c[`${this.cohortInterval}s`]?.length - 1
                        ]
                      }`
                    : ``
                } Trigger ${_i + 1}`,
                color: '#' + Math.floor(Math.random() * 16777215).toString(16),
                hasBullet: false,
                noRisers: true,
              }
            })
          return [...p, ...mob_triggers]
        }, [])
  }

  protected getUnpreppedChartSeries(
    tableData: any[],
    defaultSeries: any,
    triggerSeries: any
  ): any[] {
    const seriesOfInterest =
      this.specialCovenant == 'Table'
        ? tableData.filter(
            (t: any) =>
              moment.utc(t.cohort).format('DD-MM-YYYY') ==
              moment.utc(this.selectedCohort).format('DD-MM-YYYY')
          )
        : tableData

    return [
      ...seriesOfInterest.map((x: any) =>
        this.specialCovenant == 'Table'
          ? {
              type: 'ColumnSeries',
              label:
                this.selectedCohort == 'Summary'
                  ? 'Summary'
                  : moment.utc(x.cohort).format('YYYY-MM-DD'),
              field: moment.utc(x.cohort).format('YYYY-MM-DD'),
              setColor: this.setColor,
            }
          : {
              ...defaultSeries,
              label: moment.utc(x.cohort).format('MMM-YY'),
              field: moment.utc(x.cohort).format('YYYY-MM-DD'),
              color: '#' + Math.floor(Math.random() * 16777215).toString(16),
            }
      ),
      ...triggerSeries,
    ]
  }

  protected getSpecialSeries(
    specialSeriesLabels: string[],
    specialSeriesFields: string[],
    unpreppedChartSeries: any[]
  ) {
    return specialSeriesFields.map((s: any, i: number) => {
      return {
        label: specialSeriesLabels[i],
        tooltipValueFormat: this.getLabelFormat(this.data),
        type: 'SmoothedXLineSeries',
        field: s,
        color:
          chartColors[i + unpreppedChartSeries.length] ??
          '#' + Math.floor(Math.random() * 16777215).toString(16),
        hasBullet: false,
        isSpecial: true,
      }
    })
  }

  protected getChartData(
    tableData: any[],
    unprepedData: any[],
    specialSeriesFields: string[],
    triggers: any[],
    xArray: string[]
  ): any[] {
    return this.specialCovenant == 'Table' && this.selectedCohort == 'Summary'
      ? xArray
          .sort((a, b) => (parseFloat(a) > parseFloat(b) ? 1 : -1))
          .map(xa => {
            const newObj: any = {}
            const summaryData =
              tableData.filter(td => td.cohort == 'Summary')[0] ?? []
            for (let i = 1; i <= this.data.triggers?.length; i++) {
              newObj[`trigger_${i}`] = summaryData[`trigger_${xa}_${i}_val`]

              summaryData[`trigger_${xa}_${i}_val`] > this.maxVal &&
                (this.maxVal = parseFloat(
                  summaryData[`trigger_${xa}_${i}_val`]
                ))
              summaryData[`trigger_${xa}_${i}_val`] < this.minVal &&
                (this.minVal = parseFloat(
                  summaryData[`trigger_${xa}_${i}_val`]
                ))
            }
            return {
              x: xa,
              ...newObj,
            }
          })
      : unprepedData
          .reduce((p, c) => {
            const key = moment.utc(c.cohort).format('YYYY-MM-DD')
            c[this.cohortColumnName] > this.maxVal &&
              (this.maxVal = parseFloat(c[this.cohortColumnName]))
            // TODO: different path
            c[this.cohortColumnName] < this.minVal &&
              (this.minVal = parseFloat(c[this.cohortColumnName]))

            return [
              ...p,
              {
                x: c[this.cohortInterval],
                [this.cohortInterval]: c[this.cohortInterval],
                trigger_value: c.trigger_value,
                triggers: this.data.triggers?.length ?? 0,
                [key]: Number(c[this.cohortColumnName]),
                cohort: key.substring(0, 'YYYY-MM-DD'.length),
                [`value_${c[this.cohortInterval]}`]: c[this.cohortColumnName],
                ...specialSeriesFields.reduce((_s: any, _c: any) => {
                  return {
                    ..._s,
                    ...Array(specialSeriesFields.length)
                      .fill('')
                      .reduce(__p => {
                        return {
                          ...__p,
                          [`${_c}`]: c[`${_c}`],
                        }
                      }, {}),
                  }
                }, {}),
                ...triggers.reduce((_p: any, _c: any) => {
                  return _c[`${this.cohortInterval}s`].includes(
                    c[this.cohortInterval]
                  ) && this.data?.triggers
                    ? {
                        ..._p,
                        ...Array(this.data?.triggers.length)
                          .fill('')
                          .reduce((__p, __, __i) => {
                            return {
                              ...__p,
                              [`trigger_${__i + 1}`]: _c[`trigger_${__i + 1}`],
                            }
                          }, {}),
                      }
                    : _p
                }, {}),
              },
            ]
          }, [])
          .sort((a: any, b: any) =>
            a[this.cohortInterval] > b[this.cohortInterval] ? 1 : -1
          )
  }

  protected getOverviewData(triggers: any, unprepedData: any[]) {
    return triggers.reduce((px: any[], cx: any) => {
      const mob_trigger = Array(
        this.data?.triggers ? this.data?.triggers[0].trigger : {}
      )
        .fill('')
        .map((_, _i) => {
          return {
            ...cx,
            trigger: `MoB ${cx.mobs?.[0]}${
              cx.mobs.length > 1 ? `-${cx.mobs?.[cx.mobs?.length - 1]}` : ``
            } Trigger ${_i + 1}`,
            breach: unprepedData?.reduce((p, c) => {
              const trigger = Number(c.trigger)
              return cx.mobs.includes(c.mob) ? p + trigger : p
            }, 0),
          }
        })
      return [...px, ...mob_trigger]
    }, [])
  }

  protected getTableHeaderData(triggers: any) {
    return Array(this.data?.triggers?.length ?? 0)
      .fill('')
      .map((_, i) => {
        return {
          cohort: `Trigger ${i + 1}`,
          ...triggers.reduce((p: any, c: any) => {
            const value_mob = c.mobs.reduce((_p: any, _c: any) => {
              return {
                ..._p,
                [`value_${_c}`]: c[`trigger_${i + 1}`],
              }
            }, {})
            return { ...p, ...value_mob }
          }, {}),
        }
      })
  }

  protected getTableData(summaryVals: any, unpreppedtableData: any) {
    return this.specialCovenant == 'Table'
      ? [summaryVals, ...unpreppedtableData]
      : [...unpreppedtableData]
  }

  protected getUnfilteredData() {
    return [this.covenant]
      .reduce((p: any[], c) => {
        return [...p, ...(c.data ?? [])]
      }, [])
      .sort((a, b) => (a.cohort > b.cohort ? 1 : -1))
  }

  protected getUnprepedData(unfilteredData: any[]) {
    return this.specialCovenant == 'Table'
      ? unfilteredData.filter(
          p =>
            moment.utc(p.cohort).format('DD-MM-YYYY') ==
            moment.utc(this.selectedCohort).format('DD-MM-YYYY')
        )
      : unfilteredData
  }

  protected getDefaultSeries() {
    return {
      tooltipValueFormat: this.getLabelFormat(this.data),
      type: 'SmoothedXLineSeries',
      hasBullet: false,
    }
  }

  public build() {
    const unprepColumns = [this.covenant].reduce((p: any[], c) => {
      const newColumns = c.data.map((x: any) => x[this.cohortInterval])
      return [...p, ...newColumns]
    }, [])
    const preppedColumns = this.getPreppedColumns(unprepColumns)
    const cohortCol = this.getCohortColumn()
    const triggerCol = this.getTriggerColumn()
    const tableColumns =
      this.specialCovenant == 'Table'
        ? [cohortCol, triggerCol, ...preppedColumns]
        : [cohortCol, ...preppedColumns]

    const summaryVals: any = {}

    const unpreppedtableData = this.getUnpreppedTableData(
      this.specialSeriesFields,
      summaryVals
    )

    const tableData = this.getTableData(summaryVals, unpreppedtableData)

    const unfilteredData = this.getUnfilteredData()
    const unprepedData = this.getUnprepedData(unfilteredData)

    const unpreppedTriggers = this.getUnpreppedTriggers(unprepedData)
    const triggers = unpreppedTriggers.map((x: any) => ({
      ...x,
      [this.cohortInterval]: Math.max(...x[`${this.cohortInterval}s`]),
    }))

    // Chart Data population
    const summaryData = tableData.filter(td => td.cohort == 'Summary')[0] ?? []
    const regex = /trigger_\d+_\d+_val/
    const matchingKeys = Object.keys(summaryData).filter(s => regex.test(s))
    const xArray: string[] = []
    matchingKeys.forEach(
      (mk: string) =>
        !xArray.includes(mk.split('_')[1]) && xArray.push(mk.split('_')[1])
    )

    const chartData = this.getChartData(
      tableData,
      unprepedData,
      this.specialSeriesFields,
      triggers,
      xArray
    )

    // Series Data population
    const defaultSeries = this.getDefaultSeries()
    const triggerSeries = this.getTriggerSeries(triggers)

    const unpreppedChartSeries = this.getUnpreppedChartSeries(
      tableData,
      defaultSeries,
      triggerSeries
    )

    const specialSeries = this.getSpecialSeries(
      this.specialSeriesLabels,
      this.specialSeriesFields,
      unpreppedChartSeries
    )

    let chartSeries = [...unpreppedChartSeries]
    if (this.specialSeriesFields[0] !== '') {
      chartSeries = [...chartSeries, ...specialSeries]
    }

    const overviewColumns = [
      {
        title: 'Trigger',
        field: 'trigger',
        align: 'center',
      },
      { title: 'Breach', field: 'breach', align: 'center' },
    ]
    const overviewData = this.getOverviewData(triggers, unprepedData)
    const tableHeaderData = this.getTableHeaderData(triggers)
    const ySetting = {
      // Set maximun value of axis to maxValue + 10% or 10 points, whichever is lower.
      // Otherwise, the maxValue tops out the chart
      max: this.maxVal + Math.min(this.maxVal > 0 ? this.maxVal * 0.1 : 1, 10),
      min: this.minVal,
    }
    return {
      tableColumns,
      tableData,
      chartData,
      chartSeries,
      maxVal: this.maxVal,
      minVal: this.minVal,
      selectedCohort: this.selectedCohort,
      specialSeries,
      overviewColumns,
      overviewData,
      tableHeaderData,
      invertColours: this.invertedColours,
      ySetting: ySetting,
    }
  }
}
