import React from 'react'

import BaseLayout from '@components/layouts/base-layout'

const InvestorBenchmark = () => {
  return (
    <BaseLayout title="Benchmark">
      <div></div>
    </BaseLayout>
  )
}

export default InvestorBenchmark
