import { formatInTimeZone } from 'date-fns-tz'
import moment from 'moment'

import {
  DashboardTrigger,
  SeriesConfig,
} from '@interfaces/manage-monitor-dashboard'

export interface ChartResponse {
  [key: string]: any
}

export abstract class ChartDataBuilder {
  private dateField = ''
  data: ChartResponse
  unit = ''
  maxYAxisValue = 0
  minYAxisValue = 0

  /**
   *
   * @param data - raw data from lambda
   * @param calculationDate - calculation date from lambda
   * @param reduceStackedDataFunction - a function to organize data for stacked graph. This will be used to build chart data
   * @param chartTypePropertyName - property name from the Lambda response, which is used to define the type of the character.
   */
  constructor(
    data: ChartResponse,
    private calculationDate: string,
    private reduceStackedDataFunction: (
      existingIndex: number,
      acc: any,
      curr: any,
      xValue: any
    ) => void,
    private chartTypePropertyName: string
  ) {
    this.data = data
    this.unit = this.data.data?.[0]?.unit
    this.dateField = this.data.calculation_date_filter_field ?? ''
    this.maxYAxisValue = 0
    this.minYAxisValue = 0
  }

  // TODO: this is updated as we iterate through data.
  // It adds a buffer of 10 units above/below - which
  // means we could end up with different range depending
  // on which elements get filtered.
  // Further - this is called as part of "map" operations,
  // mutating state - that's *WRONG!* and needs to be fixed.
  private updateYAxisRange(value: number) {
    if (value > this.maxYAxisValue) {
      this.maxYAxisValue = value
    }

    if (value < this.minYAxisValue) {
      this.minYAxisValue = value
    }
  }

  private filterDataByDate(data: any[]) {
    const filteredData = data.filter(d => {
      const targetDate = formatInTimeZone(
        d.data[this.dateField] ?? d.data.calculation_date ?? d.calculation_date,
        'UTC',
        'yyyy-MM-dd'
      )
      return targetDate === this.calculationDate
    })
    return filteredData
  }

  private formatData(data: any) {
    return data.map((d: any) => {
      const formattedObject: any = {
        x:
          this.data.x_axis_type === 'CategoryAxis'
            ? d[this.data.x_axis_field]
            : moment.utc(d[this.data.x_axis_field]).valueOf(),
        [this.data.y_axis_field]: d[this.data.y_axis_field],
      }
      this.updateYAxisRange(d[this.data.y_axis_field])

      if (this.data.triggers && this.data.triggers.length > 0) {
        this.data.triggers.forEach(
          (trigger: { field: string; label: string }) => {
            this.updateYAxisRange(d[trigger.field])
            return (formattedObject[trigger.field] = d[trigger.field])
          }
        )
      }

      return formattedObject
    })
  }

  private reduceStackedData(data: any[]) {
    return data.reduce((acc, curr) => {
      curr.data.data.forEach((dt: { [x: string]: number }) =>
        this.updateYAxisRange(dt[this.data.y_axis_field])
      )

      const xValue =
        this.data.x_axis_type === 'CategoryAxis'
          ? curr.data[this.data.x_axis_field]
          : moment.utc(curr.data[this.data.x_axis_field]).valueOf()
      const existingIndex = acc.findIndex((item: any) => item.x === xValue)

      if (this.reduceStackedDataFunction) {
        this.reduceStackedDataFunction(existingIndex, acc, curr, xValue)
      }

      return acc
    }, [])
  }

  protected getType(field: string) {
    if (
      this.data[this.chartTypePropertyName] === 'stacked_area' ||
      this.data[this.chartTypePropertyName] === 'total_stacked_area' ||
      this.data[this.chartTypePropertyName] === 'line_graph'
    ) {
      return 'SmoothedXLineSeries'
    } else if (
      this.data[this.chartTypePropertyName] === 'total_stacked_graph' ||
      this.data[this.chartTypePropertyName] === 'stacked_graph'
    ) {
      return 'ColumnSeries'
    } else {
      const series = (this.data.series_config ?? []).find(
        (series: any) => series.series_key === field
      )
      if (!series) {
        return 'ColumnSeries'
      }
      switch (series.series_type) {
        case 'column':
          return 'ColumnSeries'
        case 'line':
          return 'SmoothedXLineSeries'
        default:
          return 'UnknownSeriesType'
      }
    }
  }

  public getLabels(field: string) {
    if (!Array.isArray(this.data.series_config)) {
      return field
    }
    const series = (this.data.series_config ?? []).find(
      (series: SeriesConfig) => series.series_key === field
    )
    return series?.label ?? field
  }

  private getFilteredTableData(data: any[]) {
    const dateFilteredData = this.filterDataByDate(data)[0]
    const unpreppedTableData = dateFilteredData?.data.data

    if (!Array.isArray(unpreppedTableData)) {
      return []
    }
    return unpreppedTableData.map((d: any) => ({
      [this.data.x_axis_field]: d[this.data.x_axis_field],
      ...d.data,
    }))
  }

  private getSeriesConfig(field: string) {
    if (!Array.isArray(this.data.series_config)) {
      return null
    }
    return (this.data.series_config ?? []).find(
      (series: SeriesConfig) => series.series_key === field
    )
  }

  protected getTooltipLabelFormatting(field = '') {
    const seriesConfig = this.getSeriesConfig(field)
    return `${
      seriesConfig?.tooltip_format ?? this.data.y_axis_format ?? '#.00a'
    } ${this.unit ?? ''}`
  }

  protected getFilteredData(): any[] {
    return this.calculationDate &&
      this.dateField &&
      !['line_graph'].includes(this.data[this.chartTypePropertyName])
      ? this.filterDataByDate(this.data.data ?? [])
      : this.data.data
  }

  protected getChartData = (filteredData: any[]) => {
    const useStackedData = [
      'stacked_area',
      'total_stacked_area',
      'total_stacked_graph',
      'stacked_graph',
      'combo_series',
      'line_graph',
    ].includes(this.data[this.chartTypePropertyName])

    const foundData = this.data.data
    if (!foundData) {
      return []
    }

    if (useStackedData) {
      return this.reduceStackedData(foundData)
    }

    const useFlattenedData = ['donut_chart'].includes(
      this.data[this.chartTypePropertyName]
    )

    if (useFlattenedData) {
      // Warning: Y Axis min/max weren't configured through this path!
      return filteredData?.map((d: any) => d.data.data).flat()
    }

    // This looks wrong - it's mapping to a constant data source!
    return this.formatData(filteredData?.flatMap(d => d.data.data ?? []) ?? [])
  }

  protected getFilteredChartData = (chartData: any) => {
    const hasDate =
      this.data.x_axis_type === 'DateAxis' &&
      this.calculationDate &&
      this.dateField

    if (hasDate) {
      return chartData.filter((cd: any) => {
        const cdDate = moment.utc(cd.x)
        const endDate = moment.utc(this.calculationDate)
        let startDate = endDate
        /*
         * Giving data control over the chart to display the interval of data being displayed
         * with relevance to selected calculation date
         * exp: 7 day charts, 14 day charts, 30 day charts etc
         */
        if (this.data.limit_historical_value) {
          startDate = endDate
            .clone()
            .subtract(
              this.data.limit_historical_value,
              this.data.limit_historical_interval ?? 'days'
            )
        }

        if (this.data.limit_future_value) {
          endDate.add(
            this.data.limit_future_value,
            this.data.limit_future_interval ?? 'days'
          )
        }

        return this.data.limit_historical_value || this.data.limit_future_value
          ? cdDate.isBetween(startDate, endDate, 'days', '(]')
          : cdDate.isSameOrBefore(endDate, 'day')
      })
    }

    return chartData
  }

  protected getTriggerSeries = () => {
    return (this.data.triggers ?? []).map((t: DashboardTrigger) => ({
      label: t.label ?? '',
      tooltipValueFormat: `${this.data.y_axis_format ?? '#.00a'} ${
        this.unit ?? ''
      }`,
      type: 'SmoothedXLineSeries',
      // TOOD: we should pull *consistently* from some colour palette!
      color: '#' + Math.floor(Math.random() * 16777215).toString(16),
      hasBullet: true,
      bulletType: 'line',
      field: t.field ?? '',
    }))
  }

  protected getXAxisType = (): 'DateAxis' | 'CategoryAxis' => {
    return this.data.x_axis_type === 'DateAxis' ? 'DateAxis' : 'CategoryAxis'
  }

  protected getSecondaryYAxisConfig = () => {
    return {
      secondaryYLabel: this.data.y_axis_2_label ?? '',
      secondaryYFormat: this.data.y_axis_2_format ?? '#.00a',
    }
  }

  private getColumnNames = () => {
    return (this.data.y_axis_label ?? '').split(',')
  }

  protected getTableColumns = () => {
    return (this.data.y_axis_field ?? '')
      .split(',')
      .map((col: string, i: number) => ({
        title: this.getColumnNames()[i] ?? col, //falls back to column name(ugly) if label not given so header isnt empty
        field: col.trim(),
        align: 'left',
      }))
  }

  protected getTableData = () => {
    if (this.dateField) {
      return this.getFilteredTableData(this.data?.data ?? [])
    }

    return (this.data.data ?? []).map((d: any) => ({
      [this.data.x_axis_field]: d[this.data.x_axis_field],
      ...d.data,
    }))
  }

  protected getDefaultUnsortedSeries(triggerSeries: []): any {
    return [
      {
        label: this.data.y_axis_label ?? '',
        tooltipValueFormat: `${this.data.y_axis_format ?? '#.00a'} ${
          this.unit ?? ''
        }`,
        type:
          this.data.display === 'line_graph'
            ? 'SmoothedXLineSeries'
            : 'ColumnSeries',
        field: this.data.y_axis_field,
      },
      ...triggerSeries,
    ]
  }
}
