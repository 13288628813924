import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import Table from '@components/table'
import { SortOrder } from '@components/table/type'
import AuthContext from '@contexts/auth'
import { useActiveFacility } from '@helpers/active-facility-hook'
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { Card, CardBody } from '@material-tailwind/react'
import DocumentService from '@services/api-manage/docs'

import { capitalizeWords } from '../../../../../helpers/string-formatter'
import { OptionsMenu } from '../options-menu'

interface Props {
  action: 'upload' | 'create'
}

const uploadCategoryList = [
  'Portfolio Covenant Certificate',
  'Unaudited Financial Statements',
  'Audited Financial Statements',
  'Borrowing Base Certificate',
  'Cash Balance Certificate',
  'Certificate of Withholding',
  'Waterfall Report',
  'Financial Plan',
  'Required Filings',
  'AML Event Notices',
  'Loan & Security',
  'Other',
  'Advance Request',
  'Compliance Certificate',
  'Financial Covenant Certificate',
  'Collections Report',
  'Board of Director Reports',
  'VAT Proof of Payment',
]

export const ReportingCategoriesTable = ({ action }: Props) => {
  const { company } = useContext(AuthContext)
  const [sortField, setSortField] = useState<string>('status')
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  const [dataArray, setDataArray] = useState<CategoriesResult[]>([])
  const activeFacility = useActiveFacility()

  const {
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm()

  const params = {
    facility: activeFacility?.facility_name,
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacility?.facility_id,
  }

  const { data, isLoading, refetch } = useQuery(
    ['getReportingCategoriesRequest', params, action],
    () =>
      DocumentService.listCategories({
        ...params,
        action,
      }),
    {
      enabled: !!company && !!activeFacility,
    }
  )
  useEffect(() => data && setDataArray(data), [data])

  const { mutate: updateCategory, isLoading: createLoading } = useMutation(
    (values: CategoriesResult) =>
      DocumentService.updateCategory({
        ...params,
        entry: values,
      }),
    {
      onSuccess: () => {
        refetch()
        reset()
      },
    }
  )

  const { mutate: deleteCategory, isLoading: deleteLoading } = useMutation(
    (id: string) =>
      DocumentService.deleteCategory({
        ...params,
        id: id,
      }),
    {
      onSuccess: () => {
        refetch()
        reset()
      },
    }
  )

  const onSubmit = (values: any) => {
    updateCategory(values)
  }

  const handleEdit = (id: string) => {
    const entry = dataArray.find(e => e.id === id)
    setValue('action', action, { shouldValidate: true })
    if (!entry) {
      return
    }
    Object.keys(entry).forEach((key: string) => {
      setValue(key, entry[key as keyof CategoriesResult], {
        shouldValidate: true,
      })
    })
  }

  const cancelEdit = () => {
    if (dataArray?.length && !dataArray[dataArray.length - 1].id) {
      setDataArray(dataArray.slice(0, dataArray.length - 2))
    }
    reset()
  }

  const autoGenerateUploads = () => {
    uploadCategoryList.forEach(category => {
      updateCategory({
        category,
        subcategory: 'default',
        action: 'upload',
      })
    })
  }

  const isWorking = createLoading || deleteLoading

  return (
    <Card className="rounded-md h-full w-full">
      <CardBody className="flex flex-col items-left ">
        <div className="flex justify-between w-full">
          <div className="text-lg font-bold">
            Reporting Categories - {capitalizeWords(action)}
          </div>
          <div className="flex gap-2">
            <button
              className="flex justify-center items-center w-24 h-10 py-1 mb-2 text-sm font-medium cursor-pointer rounded-lg bg-primary-main hover:bg-primary-hover text-white transition ease-in-out delay-50 duration-300"
              onClick={() => {
                setValue('action', action, { shouldValidate: true })
                setDataArray([...dataArray, { action } as CategoriesResult])
              }}
              disabled={
                isWorking ||
                (!!dataArray?.length &&
                  (!dataArray[dataArray.length - 1].id || !!getValues('id')))
              }
            >
              <PlusIcon className="w-4 h-4 mr-2 -ml-2" />
              Add
            </button>
            {action === 'upload' && (
              <button
                className="flex justify-center items-center w-24 h-10 py-1 mb-2 text-sm font-medium cursor-pointer rounded-lg bg-primary-main hover:bg-primary-hover text-white transition ease-in-out delay-50 duration-300"
                onClick={() => autoGenerateUploads()}
                disabled={
                  isWorking ||
                  (!!dataArray?.length &&
                    (!dataArray[dataArray.length - 1].id || !!getValues('id')))
                }
              >
                <PlusIcon className="w-4 h-4 mr-2 -ml-2" />
                Auto
              </button>
            )}
            <Button
              color="default"
              className="mb-2 "
              onClick={() => {
                refetch()
              }}
            >
              <ArrowPathIcon
                className={`${isLoading ? 'animate-spin' : ''} w-4 h-4 `}
              />
            </Button>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Table
            loading={isLoading}
            columns={[
              {
                title: 'Category',
                field: 'category',
                sortable: true,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="text"
                      value={getValues('category')}
                      {...register('category', {
                        required: 'Category is required',
                        minLength: 1,
                        maxLength: 2048,
                        disabled: isWorking,
                        onChange: (e: any) => {
                          setValue('category', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: e => {
                          setValue('category', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                      })}
                      error={errors.category?.message as string}
                    />
                  ) : (
                    entry.category
                  ),
              },
              {
                title: 'Subcategory',
                field: 'subcategory',
                sortable: true,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="text"
                      value={getValues('subcategory')}
                      {...register('subcategory', {
                        required: 'Subcategory is required',
                        minLength: 1,
                        maxLength: 2048,
                        disabled: isWorking,
                        onChange: (e: any) => {
                          setValue('subcategory', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('subcategory', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                      })}
                      error={errors.subcategory?.message as string}
                    />
                  ) : (
                    entry.subcategory
                  ),
              },
              {
                title: 'Options',
                field: 'signers',
                sortable: true,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="text"
                      error={errors.signers?.message as string}
                      value={getValues('signers')}
                      {...register('signers', {
                        minLength: 1,
                        maxLength: 2048,
                        disabled: isWorking,
                        onChange: (e: any) => {
                          setValue('signers', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('signers', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        validate: (value: string) => {
                          try {
                            if (value) {
                              JSON.parse(value)
                            }
                          } catch {
                            return 'Invalid JSON'
                          }
                        },
                      })}
                    />
                  ) : (
                    entry.signers
                  ),
              },
              {
                title: 'Custom Fields',
                field: 'custom_fields',
                sortable: true,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="text"
                      error={errors.custom_fields?.message as string}
                      value={getValues('custom_fields')}
                      {...register('custom_fields', {
                        minLength: 1,
                        maxLength: 2048,
                        disabled: isWorking,
                        onChange: (e: any) => {
                          setValue('custom_fields', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('custom_fields', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        validate: (value: string) => {
                          try {
                            if (value) {
                              JSON.parse(value)
                            }
                          } catch {
                            return 'Invalid JSON'
                          }
                        },
                      })}
                    />
                  ) : (
                    entry.custom_fields
                  ),
              },
              {
                title: 'Actions',
                field: 'id',
                width: '64.5px',
                render: entry => (
                  <OptionsMenu
                    id={entry.id}
                    edit={entry.id === getValues('id')}
                    disableMenu={
                      isWorking || !dataArray[dataArray.length - 1].id
                    }
                    disableButtons={isWorking}
                    disableSave={!isValid}
                    handleEdit={handleEdit}
                    handleDelete={deleteCategory}
                    handleCancel={cancelEdit}
                  />
                ),
              },
            ]}
            data={dataArray}
            onSort={(field: string, order: SortOrder) => {
              setSortField(order != '' ? field : 'status')
              setSortOrder(order != '' ? order : 'asc')
            }}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        </form>
      </CardBody>
    </Card>
  )
}
