import React from 'react'

import BaseLayout from '@components/layouts/base-layout'

const InvestorOverviewL3 = () => {
  return (
    <BaseLayout title="Overview">
      <div></div>
    </BaseLayout>
  )
}

export default InvestorOverviewL3
