import React from 'react'

import BaseLayout from '@components/layouts/base-layout'

const InvestorFacilitiesL3 = () => {
  return (
    <BaseLayout title="Facilities">
      <div></div>
    </BaseLayout>
  )
}

export default InvestorFacilitiesL3
